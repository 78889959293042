<template>
    <el-drawer v-model="isDrawerShow" :show-close="false" @close="$emit('close')" size="920px"
        custom-class="rule-drawer" :close-on-click-modal="true" ref="drawerRef">
        <template #title>
            <div class="top-container">
                <span>{{ operateStatus === 'edit' ? '编辑规则' : '新建规则' }}</span>
                <span class="back" @click="isDrawerShow = false">返回</span>
            </div>
        </template>
        <div class="add-rule-container">
            <el-form :rules="rules" ref="ruleFormRef" :model="ruleForm">
                <div class="base-container">
                    <div class="title">基础设置</div>
                    <el-form-item label="规则名称：" prop="name" required>
                        <el-input v-model="ruleForm.name" placeholder="请输入规则名称" size="small" style="width: 508px;"
                            clearable :validate-event="false" />
                    </el-form-item>
                    <el-form-item label="生效时间：" prop="time_type" required style="margin-bottom: 12px;">
                        <el-row type="flex" align="middle">
                            <el-radio-group v-model="ruleForm.time_type">
                                <el-radio :label="1">长期</el-radio>
                                <el-radio :label="2">自定义</el-radio>
                            </el-radio-group>
                            <el-row type="flex" align="middle" style="margin-left: 16px;"
                                v-show="ruleForm.time_type === 2">
                                <el-select v-model="ruleForm.start_hour" placeholder="请选择" size="small">
                                    <el-option v-for="item in timeOptions" :key="item.id" :label="item.name"
                                        :value="item.id" />
                                </el-select>
                                <span style="margin: 0 8px;">至</span>
                                <el-select v-model="ruleForm.end_hour" placeholder="请选择" size="small">
                                    <el-option v-for="item in timeOptions" :key="item.id" :label="item.name"
                                        :value="item.id" />
                                </el-select>
                            </el-row>
                        </el-row>
                    </el-form-item>
                    <el-form-item label="检查频次：">
                        <span style="color:#595959">每半个小时</span>
                    </el-form-item>
                </div>
                <div class="rule-container">
                    <div class="title">规则设置</div>
                    <div class="condition-container">
                        <span class="title">规则条件</span>
                        <div class="item" style="padding: 20px 16px 24px 16px;">
                            <Rule ref="ruleRef" v-model:ruleList="ruleForm.rule" :options="ruleOptions"
                                :level="ruleForm.ads_level" :type="ruleForm.type"
                                @desc="e => ruleForm.description = e" />
                        </div>
                        <div class="item">
                            <el-form-item label="规则应用层级：" prop="ads_level" required class="rule-apply-level">
                                <el-radio-group v-model="ruleForm.ads_level" :disabled="operateStatus === 'edit'">
                                    <el-radio label="campaign">广告系列</el-radio>
                                    <el-radio label="adset">广告系列中的广告组</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </div>
                        <div class="item">
                            <el-form-item label="规则操作：" prop="type" required class="rule-operate">
                                <el-select v-model="ruleForm.type" placeholder="请选择" size="small"
                                    :validate-event="false">
                                    <el-option v-for="item in typeOptions" :key="item.id" :label="item.name"
                                        :value="item.id" />
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="item">
                            <div class="rule-desc-title" @click="isShowDesc = !isShowDesc">
                                <span>规则描述</span>
                                <el-icon>
                                    <i class="iconfont el-icon-arrow-down" v-show="isShowDesc"></i>
                                    <i class="iconfont el-icon-arrow-right" v-show="!isShowDesc"></i>
                                </el-icon>
                            </div>
                            <div v-html="ruleForm.description" class="rule-desc-content" v-if="isShowDesc">
                            </div>
                        </div>
                    </div>
                    <el-form-item label="消息通知：">
                        <el-row type="flex" align="middle">
                            <vxe-checkbox v-model="ruleForm.is_notice" size="medium"
                                style="margin-right: 8px;">发送通知到：</vxe-checkbox>
                            <div class="email-list">
                                <el-row type="flex" v-if="!ruleForm.editEmail">
                                    <span v-for="(item, index) in ruleForm.email" :key="index" class="email">{{
                                        item }}</span>
                                    <span class="button" @click="ruleForm.editEmail = true">编辑</span>
                                </el-row>
                                <el-row type="flex" align="middle" v-else>
                                    <div v-for="(item, index) in ruleForm.email" :key="index" class="email-tag">
                                        <span>{{ item }}</span>
                                        <el-icon @click="handleDeleteEmail(index)">
                                            <i class="iconfont icon-ylclose"></i>
                                        </el-icon>
                                    </div>
                                    <el-form-item prop="emailInput" style="margin-bottom: 0;">
                                        <el-input v-model="ruleForm.emailInput" placeholder="回车确定输入邮箱" size="small"
                                            style="width:160px;" clearable :validate-event="false"
                                            @keyup.enter.native="addEmail" />
                                    </el-form-item>
                                </el-row>
                            </div>
                        </el-row>
                    </el-form-item>
                </div>
            </el-form>
        </div>
        <div class="footer-container">
            <el-button @click="isDrawerShow = false" size="small">取消</el-button>
            <el-button type="primary" size="small" @click="handleSubmit"
                v-show="operateStatus === 'add'">创建规则</el-button>
            <el-button @click="isSaveNewDialogShow = true" size="small"
                v-show="operateStatus === 'edit'">另存为新的规则</el-button>
            <el-button type="primary" size="small" @click="handleSubmit"
                v-show="operateStatus === 'edit'">确定</el-button>
        </div>
    </el-drawer>


    <!-- 编辑保存弹窗 -->
    <el-dialog v-model="isSaveDialogShow" width="564px" top="30vh" :close-on-click-modal="false"
        custom-class="custom-rule-edit-dialog">
        <template #title>
            <i class="iconfont icon-jingshi"></i>
            <span class="title">是否确定保存修改？</span>
        </template>
        <div class="save">规则修改后，应用此规则的广告系列将从规则下一次生效时应用修改后的新规则。</div>
        <template #footer>
            <el-button @click="isSaveDialogShow = false;" size="small">取消</el-button>
            <el-button type="primary" size="small" @click="handleEditSubmit">确定</el-button>
        </template>
    </el-dialog>

    <!-- 另存为新规则弹窗 -->
    <el-dialog v-model="isSaveNewDialogShow" width="564px" top="30vh" title="请输入新的规则名称" :close-on-click-modal="false"
        custom-class="custom-rule-edit-dialog" :destroy-on-close="true">
        <el-form :rules="rules" ref="nameFormRef" :model="ruleForm" style="margin-top: 32px;">
            <el-form-item label="规则名称：" prop="name" required>
                <el-input v-model="ruleForm.name" placeholder="请输入规则名称" size="small" clearable :validate-event="false"
                    style="width: 80%;" />
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button @click="isSaveNewDialogShow = false;" size="small">取消</el-button>
            <el-button type="primary" size="small" @click="handleSaveNewSubmit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script setup>
import { ref, getCurrentInstance } from 'vue'
import Rule from '@/views/ads/adRule/Rule'
import api from '@/request/api/ad'
import { ElMessage } from 'element-plus'

const emit = defineEmits(['close', 'submit'])
const { proxy } = getCurrentInstance()

const isDrawerShow = ref(true)
const ruleRef = ref(null)
const isShowDesc = ref(true)
const isSaveDialogShow = ref(false)
const isSaveNewDialogShow = ref(false)
const operateStatus = ref('add')
const accountInfo = ref(JSON.parse(localStorage.getItem('CurrentFBAuthUser')))
const ruleId = ref(null)
const ruleFormRef = ref(null)
const nameFormRef = ref(null)
const ruleForm = ref({
    name: '',
    time_type: 1,
    start_hour: 0,
    end_hour: 0,
    rule: {
        connector: 'AND',
        conditions: [
            {
                connector: 'AND',
                conditions: [{
                    field: '',
                    range: '',
                    operator: '',
                    value: ''
                }]
            }
        ]
    },
    type: 1,
    description: '',
    ads_level: 'campaign',
    campaigns: [],
    is_notice: true,
    editEmail: false,
    emailInput: '',
    email: []
})

const rules = {
    name: [{ required: true, message: '请输入规则名称' }],
    type: [{ required: true, message: '请选择规则操作' }],
    emailInput: [{
        pattern: /(^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$)/,
        message: '请输入正确格式的邮箱号码',
    }]
}

const ruleOptions = ref([])
const timeOptions = Array.from({ length: 24 }, (_, index) => ({ id: index, name: `${index}:00` }));
const typeOptions = ref([
    { id: 1, name: '关闭' },
    { id: 2, name: '开启' },
])

const addRule = () => {
    operateStatus.value = 'add'
    ruleForm.value = {
        name: '',
        time_type: 1,
        start_hour: 0,
        end_hour: 0,
        rule: {
            connector: 'AND',
            conditions: [
                {
                    connector: 'AND',
                    conditions: [{
                        field: '',
                        range: '',
                        operator: '',
                        value: ''
                    }]
                }
            ]
        },
        type: 1,
        description: '',
        ads_level: 'campaign',
        campaigns: [],
        is_notice: true,
        editEmail: false,
        emailInput: '',
        email: []
    }
    ruleForm.value.email.push(proxy.$store.getters.userInfo.email)
    api.rule.getRuleConfig().then(res => {
        ruleOptions.value = res.data
    })
}

const editRule = (id) => {
    operateStatus.value = 'edit'
    ruleId.value = id
    api.rule.getRuleConfig().then(res => {
        ruleOptions.value = res.data
        api.rule.editCustomRule({
            rule_id: id,
            edit_type: 0
        }).then(res => {
            ruleForm.value.ads_level = res.data.ads_level
            ruleForm.value.email = res.data.email.split(',')
            ruleForm.value.name = res.data.name
            ruleForm.value.rule = JSON.parse(res.data.evaluation)
            ruleForm.value.time_type = res.data.time_type
            ruleForm.value.start_hour = res.data.start_hour
            ruleForm.value.end_hour = res.data.end_hour
            ruleForm.value.type = res.data.type
        })
    })
}

const handleDeleteEmail = (index) => {
    if (ruleForm.value.email.length === 1) {
        ElMessage.warning('至少保留一个邮箱')
        return
    }
    ruleForm.value.email.splice(index, 1)
}

const addEmail = () => {
    ruleFormRef.value.validateField('emailInput', (error) => {
        if (error) return
        if (ruleForm.value.emailInput) ruleForm.value.email.push(ruleForm.value.emailInput)
        ruleForm.value.emailInput = ''
        ruleForm.value.editEmail = false
    })
}

function isNumber(str) {
    // 正则表达式匹配非负数，包括整数和小数
    const regex = /^[+]?[0-9]*\.?[0-9]+$/;
    return regex.test(str) && parseFloat(str) >= 0;
}


/** 保存编辑规则 */
const handleEditSubmit = () => {
    api.rule.editCustomRule({
        rule_id: ruleId.value,
        name: ruleForm.value.name,
        type: ruleForm.value.type,
        evaluation: JSON.stringify(ruleForm.value.rule),
        description: ruleForm.value.description,
        time_type: ruleForm.value.time_type,
        start_hour: ruleForm.value.time_type === 2 ? ruleForm.value.start_hour : undefined,
        end_hour: ruleForm.value.time_type === 2 ? ruleForm.value.end_hour : undefined,
        email: ruleForm.value.is_notice ? ruleForm.value.email.join(',') : undefined,
        edit_type: 1
    }).then(res => {
        isSaveDialogShow.value = false
        ElMessage.success(res.msg)
        emit('submit', res.id)
    })
}

/** 另存为新规则 */
const handleSaveNewSubmit = () => {
    nameFormRef.value.validate((valid) => {
        if (valid) {
            api.rule.addCustomRule({
                user_id: accountInfo.value.fb_user_id,
                name: ruleForm.value.name,
                type: ruleForm.value.type,
                evaluation: JSON.stringify(ruleForm.value.rule),
                description: ruleForm.value.description,
                time_type: ruleForm.value.time_type,
                start_hour: ruleForm.value.time_type === 2 ? ruleForm.value.start_hour : undefined,
                end_hour: ruleForm.value.time_type === 2 ? ruleForm.value.end_hour : undefined,
                campaigns: JSON.stringify(ruleForm.value.campaigns),
                ads_level: ruleForm.value.ads_level,
                email: ruleForm.value.is_notice ? ruleForm.value.email.join(',') : undefined
            }).then(res => {
                isSaveNewDialogShow.value = false
                ElMessage.success(res.msg)
                emit('submit', res.id)
            })
        }
    })
}

/** 提交规则 */
const handleSubmit = () => {
    ruleFormRef.value.validate((valid) => {
        if (valid) {
            for (let i = 0; i < ruleForm.value.rule.conditions.length; i++) {
                for (let j = 0; j < ruleForm.value.rule.conditions[i].conditions.length; j++) {
                    if (
                        !ruleForm.value.rule.conditions[i].conditions[j]?.field ||
                        !ruleForm.value.rule.conditions[i].conditions[j]?.range ||
                        !ruleForm.value.rule.conditions[i].conditions[j]?.operator ||
                        ruleForm.value.rule.conditions[i].conditions[j]?.value === ""
                    ) {
                        ElMessage.warning('请完善规则条件')
                        return
                    }
                    if (!isNumber(ruleForm.value.rule.conditions[i].conditions[j]?.value)) {
                        ElMessage.warning('规则条件的数值必须为大于等于0的数字或小数')
                        return
                    }
                }
            }

            if (operateStatus.value === 'add') {
                api.rule.addCustomRule({
                    user_id: accountInfo.value.fb_user_id,
                    name: ruleForm.value.name,
                    type: ruleForm.value.type,
                    evaluation: JSON.stringify(ruleForm.value.rule),
                    description: ruleForm.value.description,
                    time_type: ruleForm.value.time_type,
                    start_hour: ruleForm.value.time_type === 2 ? ruleForm.value.start_hour : undefined,
                    end_hour: ruleForm.value.time_type === 2 ? ruleForm.value.end_hour : undefined,
                    campaigns: JSON.stringify(ruleForm.value.campaigns),
                    ads_level: ruleForm.value.ads_level,
                    email: ruleForm.value.is_notice ? ruleForm.value.email.join(',') : undefined
                }).then(res => {
                    ElMessage.success(res.msg)
                    emit('submit', res.id)
                })
            } else {
                isSaveDialogShow.value = true
            }
        }
    })
}

defineExpose({
    addRule,
    editRule
})
</script>

<style lang="less">
.rule-drawer {
    .el-drawer__header {
        height: 64px;
        padding: 0;
        margin: 0;
        display: block;
    }

    .el-drawer__body {
        padding: 24px 24px 88px 24px;
        margin: 0;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background: #d9d9d9;
            border-radius: 8px;
        }
    }
}

.custom-rule-edit-dialog {
    &>.el-dialog__header {
        padding: 16px 20px;
        border-bottom: 1px solid #f0f0f0 !important;

        .iconfont {
            color: #ffb900;
            font-size: 26px;
            margin-right: 8px;
            vertical-align: bottom;
        }

        span {
            font-weight: 400;
            font-size: 18px;
            color: #262626;
            line-height: 26px;
        }
    }

    .el-dialog__body {
        padding-top: 5px;
    }

    .el-dialog__footer {
        padding: 0 20px 40px 0 !important;
    }

    .el-button+.el-button {
        margin-left: 16px;
    }

    .save {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #262626;
        margin-top: 24px;
        margin-bottom: 20px;
    }
}
</style>

<style scoped lang="less">
.top-container {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 24px;
    background: #2b82ff;

    span {
        font-size: 16px;
        font-family: PingFang SC-Regular;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;
    }

    .back {
        font-size: 14px;
        cursor: pointer;
    }
}

.footer-container {
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 920px;
    height: 64px;
    background: #ffffff;
    box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    padding: 16px 24px;
    justify-content: flex-end;

    :deep(.el-button) {
        margin-left: 20px;
        font-family: PingFang SC-Medium;
        font-size: 14px;
    }

    :deep(.el-button.is-disabled) {
        color: #606266;
        border-color: #c6e2ff;
    }
}

.add-rule-container {
    :deep(.el-radio-group) {
        height: 32px;

        .el-radio {
            height: 32px;
        }
    }

    :deep(.el-form-item) {
        margin-bottom: 16px;

        .el-form-item__label {
            width: 120px;
            line-height: 32px;
            padding-right: 0;
            margin-right: 32px;
            color: #262626 !important;
        }

        .el-form-item__content {
            line-height: 32px;
        }
    }

    .title {
        font-family: PingFang SC-Medium;
        font-weight: 500;
        font-size: 14px;
        color: #8C8C8C;
        margin-bottom: 16px;
    }

    .rule-container {
        margin-top: 22px;

        .condition-container {
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            min-height: 323px;
            border-radius: 8px;
            border: 1px solid #DCDFE6;
            padding: 20px 0 24px 8px;
            gap: 16px;
            margin-bottom: 16px;

            .title {
                font-weight: 400;
                color: #262626;
                margin-left: 8px;
                margin-bottom: 0;
            }

            .item {
                background: #F5F7FA;
                border-radius: 4px 4px 4px 4px;

                .rule-apply-level,
                .rule-operate {
                    margin: 8px 0;

                    :deep(.el-form-item__label) {
                        width: 134px;
                    }
                }

                .rule-desc-title {
                    box-sizing: border-box;
                    width: 140px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    padding: 8px;
                    font-size: 14px;
                    color: #262626;
                    cursor: pointer;

                    .iconfont {
                        font-size: 10px;
                    }
                }

                .rule-desc-content {
                    font-size: 14px;
                    color: #262626;
                    margin-left: 84px;
                    margin-bottom: 24px;
                }
            }
        }

        :deep(.vxe-checkbox .vxe-checkbox--icon) {
            box-shadow: none !important;
        }

        :deep(.vxe-checkbox--label) {
            line-height: 32px;
            color: #262626;
            font-family: PingFangSC-Regular, PingFang SC;
        }

        .email-list {
            .email {
                font-size: 14px;
                color: #2B82FF;
                margin-right: 12px;
            }

            .button {
                font-size: 14px;
                color: #2B82FF;
                cursor: pointer;
            }

            .email-tag {
                height: 24px;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 4px;
                background: #F4F4F5;
                border: 1px solid #DCDFE6;
                border-radius: 4px;
                margin-right: 8px;
                line-height: unset;

                span {
                    font-size: 12px;
                    color: #595959;
                    margin-right: 6px;
                }

                .iconfont {
                    font-size: 12px;
                    cursor: pointer;
                    color: #8c8c8c;
                }
            }
        }
    }
}
</style>